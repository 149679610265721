<template>
  <model title="Tip" tag="提示框">
    <h3>基础用法</h3>
    <p>通过 placement 属性设置提示框的显示位置。</p>
    <table style="margin-left: 50px;">
      <tr>
        <td></td>
        <td>
          <lh-tip placement="top-left" text="上左">
            <lh-button>上左</lh-button>
          </lh-tip>
        </td>
        <td>
          <lh-tip placement="top-center" text="上中">
            <lh-button>上中</lh-button>
          </lh-tip>
        </td>
        <td>
          <lh-tip placement="top-right" text="上右">
            <lh-button>上右</lh-button>
          </lh-tip>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>
          <lh-tip placement="left-top" text="左上">
            <lh-button>左上</lh-button>
          </lh-tip>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <lh-tip placement="right-top" text="右上">
            <lh-button>右上</lh-button>
          </lh-tip>
        </td>
      </tr>
      <tr>
        <td>
          <lh-tip placement="left-center" text="左中">
            <lh-button>左中</lh-button>
          </lh-tip>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <lh-tip placement="right-center" text="右中">
            <lh-button>右中</lh-button>
          </lh-tip>
        </td>
      </tr>
      <tr>
        <td>
          <lh-tip placement="left-bottom" text="左下">
            <lh-button>左下</lh-button>
          </lh-tip>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <lh-tip placement="right-bottom" text="右下">
            <lh-button>右下</lh-button>
          </lh-tip>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <lh-tip placement="bottom-left" text="下左">
            <lh-button>下上</lh-button>
          </lh-tip>
        </td>
        <td>
          <lh-tip placement="bottom-center" text="下中">
            <lh-button>下中</lh-button>
          </lh-tip>
        </td>
        <td>
          <lh-tip placement="bottom-right" text="下右">
            <lh-button>下右</lh-button>
          </lh-tip>
        </td>
        <td></td>
      </tr>
    </table>
    <lh-collapse>
      <div slot="title">示例代码</div>
      <lh-code-editor v-model="code1" :readonly="true"></lh-code-editor>
    </lh-collapse>
    <p>通过 trigger 属性和 visible 属性设置提示框的显示方式。</p>
    <div>
      <lh-tip text="hover" trigger="hover">
        <lh-button>hover</lh-button>
      </lh-tip>
      <lh-tip text="click" trigger="click">
        <lh-button>click</lh-button>
      </lh-tip>
      <lh-tip text="自定义显示" :visible="visible">
        <lh-button @click="visible=!visible">自定义显示</lh-button>
      </lh-tip>
    </div>
    <lh-collapse>
      <div slot="title">示例代码</div>
      <lh-code-editor v-model="code2" :readonly="true"/>
    </lh-collapse>
    <h3>自定义模版</h3>
    <lh-tip>
      <lh-button>自定义模版</lh-button>
      <div slot="tip">
        自定义模版内容...<br/>
        自定义模版内容...<br/>
        自定义模版内容...<br/>
        自定义模版内容...<br/>
      </div>
    </lh-tip>
    <div>
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code3" :readonly="true"/>
      </lh-collapse>
    </div>
    <h3>属性参考表</h3>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>visible</lh-td>
        <lh-td>控制提示框的显示</lh-td>
        <lh-td>Boolean</lh-td>
        <lh-td>true | false | null</lh-td>
        <lh-td>null</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>maxWidth</lh-td>
        <lh-td>提示框最大宽度</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>500px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>placement</lh-td>
        <lh-td>显示位置</lh-td>
        <lh-td>String</lh-td>
        <lh-td>
          top-left | top-center | to-right |<br/>
          bottom-left | bottom-center | bottom-right |<br/>
          left-top | left-center | left-bottom |<br/>
          right-top | right-center | right-bottom
        </lh-td>
        <lh-td>top-center</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>color</lh-td>
        <lh-td>字体颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>black</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>backgroundColor</lh-td>
        <lh-td>提示框背景颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>white</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>text</lh-td>
        <lh-td>提示框里的文字</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td></lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>trigger</lh-td>
        <lh-td>触发机制</lh-td>
        <lh-td>String</lh-td>
        <lh-td>hover | click</lh-td>
        <lh-td>hover</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>margin</lh-td>
        <lh-td>外边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>0px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>borderRadius</lh-td>
        <lh-td>提示框圆角</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>5px</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  name:"TipView",
  components: { Model },
  data(){
    return{
      visible:false,
      code1:
        "<template>\n"+
        "  ...\n"+
        "  <lh-tip placement=\"top-left\" text=\"上左\">\n"+
        "    <lh-button>上左</lh-button>\n"+
        "  </lh-tip>\n"+
        "  <lh-tip placement=\"top-center\" text=\"上中\">\n"+
        "    <lh-button>上中</lh-button>\n"+
        "  </lh-tip>\n"+
        "  <lh-tip placement=\"top-right\" text=\"上右\">\n"+
        "    <lh-button>上右</lh-button>\n"+
        "  </lh-tip>\n"+
        "  ...\n"+
        "  <lh-tip placement=\"left-top\" text=\"左上\">\n"+
        "    <lh-button>左上</lh-button>\n"+
        "  </lh-tip>\n"+
        "  <lh-tip placement=\"left-center\" text=\"左中\">\n"+
        "    <lh-button>左中</lh-button>\n"+
        "  </lh-tip>\n"+
        "  <lh-tip placement=\"left-bottom\" text=\"左下\">\n"+
        "    <lh-button>左下</lh-button>\n"+
        "  </lh-tip>\n"+
        "  ...\n"+
        "  <lh-tip placement=\"right-top\" text=\"右上\">\n"+
        "    <lh-button>右上</lh-button>\n"+
        "  </lh-tip>\n"+
        "  <lh-tip placement=\"right-center\" text=\"右中\">\n"+
        "    <lh-button>右中</lh-button>\n"+
        "  </lh-tip>\n"+
        "  <lh-tip placement=\"right-bottom\" text=\"右下\">\n"+
        "    <lh-button>右下</lh-button>\n"+
        "  </lh-tip>\n"+
        "  ...\n"+
        "  <lh-tip placement=\"bottom-left\" text=\"下左\">\n"+
        "    <lh-button>下上</lh-button>\n"+
        "  </lh-tip>\n"+
        "  <lh-tip placement=\"bottom-center\" text=\"下中\">\n"+
        "    <lh-button>下中</lh-button>\n"+
        "  </lh-tip>\n"+
        "  <lh-tip placement=\"bottom-right\" text=\"下右\">\n"+
        "    <lh-button>下右</lh-button>\n"+
        "  </lh-tip>\n"+
        "  ...\n"+
        "</template>",
      code2:
        "<template>\n"+
        "<div>\n"+
        "  <lh-tip text=\"hover\" trigger=\"hover\">\n"+
        "    <lh-button>hover</lh-button>\n"+
        "  </lh-tip>\n"+
        "  <lh-tip text=\"click\" trigger=\"click\">\n"+
        "    <lh-button>click</lh-button>\n"+
        "  </lh-tip>\n"+
        "  <lh-tip text=\"自定义显示\" :visible=\"visible\">\n"+
        "    <lh-button @click=\"visible=!visible\">自定义显示</lh-button>\n"+
        "  </lh-tip>\n"+
        "</div>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "export default {\n"+
        "  data(){\n"+
        "    return{\n"+
        "      visible:false\n"+
        "    }\n"+
        "  }\n"+
        "}\n"+
        "</scr"+"ipt>",
      code3:
        "<template>\n"+
        "<lh-tip>\n"+
        "  <lh-button>自定义模版</lh-button>\n"+
        "  <div slot=\"tip\">\n"+
        "    自定义模版内容...<br/>\n"+
        "    自定义模版内容...<br/>\n"+
        "    自定义模版内容...<br/>\n"+
        "    自定义模版内容...<br/>\n"+
        "  </div>\n"+
        "</lh-tip>\n"+
        "</template>"
    }
  }
}
</script>

<style>

</style>